import React, { useEffect, useState } from 'react'

const BuildUpExtension = () => {

    const [url, setUrl] = useState(null);

    useEffect(() => {

        if(window.document.getElementById('build-up-extension-iframe')){
            return;
        }

        const token = window.localStorage.getItem('token')
        const _url = `https://edu-ai.buildup.am/?token=${token}`
        setUrl(_url)
    }, []);

    if(!url){
        return null;
    }
    return <iframe
        src={url}
        id="build-up-extension"
        className='buildUpIframe'
        style={{ display: 'block' }}
    />
}

export default BuildUpExtension;
