import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TitleContainer, TitleText } from 'components/styled/typography'
import Layout from 'layouts/main'
import fetch from 'node-fetch'
import CourseITem from './coursesTableItem'
import BuildUpExtension from '../components/buExtenison/ChatWindow'
import Loading from '../components/shared/loading'


const Main = styled.div`
  // margin-bottom: 100px;
  overflow-x: hidden;


  .line {
    padding: 0;
    margin: 0;
    width: 1300px;
    margin-left: 150px;
  }
`

const LongtermCourses = styled.div`
  margin-top: 100px;


`


const Courses = () => {

  const [shortTermCourses, setShortTermCourses] = useState([])

  const [longTermCourses, setLongTermCourses] = useState([])

  const base_url = 'https://hhx6cxn4j0.execute-api.us-east-1.amazonaws.com/production'

  const API_SHORT_TERM = 'https://backend.buildup.am/programs?type=short'

  const API_LONG_TERM = 'https://backend.buildup.am/programs?type=long'

  const [enrolledCourses, setEnrolledCourses] = useState([])

  useEffect(() => {
    if(enrolledCourses.length > 0){
      setShowExtension(true);
    }else{
      setShowExtension(false);
    }
  }, [enrolledCourses])

  useEffect(() => {
    fetch(API_SHORT_TERM)
      .then(response => response.json())
      .then(data => setShortTermCourses(data))
  }, [])

  useEffect(() => {
    fetch(API_LONG_TERM)
      .then(response => response.json())
      .then(data => setLongTermCourses(data))
  }, [])

  useEffect(() => {
    const token = window.localStorage.getItem('token')
    if(!token) {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = '/?showLogin=true&next=/courses'
      return;
    }

    const ENROLLED_API = `${base_url}/api/v1/enrollments/buildup?token=${token}`
    fetch(ENROLLED_API)
      .then(response => response.json())
      .then(data => setEnrolledCourses(data.map(d => parseInt(d.program.buildupId))))
  }, [])
  useEffect(() => {
    console.log({ enrolledCourses })
  }, [])

  const [loading, setLoading] = useState(false)
  const [showExtension, setShowExtension] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'extension-closed') {
        const iframe = document.getElementById('build-up-extension')
        if (iframe) iframe.style.display = 'none'
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const handleEnroll = (course) => {
    setLoading(true)
    const action = enrolledCourses.includes(course.id) ? 'unenroll' : 'enroll'
    const newEnrolledCourses = enrolledCourses.includes(course.id) ? enrolledCourses.filter(c => c !== course.id) : [...enrolledCourses, course.id]
    setEnrolledCourses(newEnrolledCourses)

    const token = window.localStorage.getItem('token')


    fetch(`${base_url}/api/v1/enrollments/buildup?token=${token}&action=${action}&programId=${course.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
      .then(data => {
        setEnrolledCourses(data.map(d => parseInt(d.program.buildupId)))
        if(action === 'enroll') {
          setShowExtension(true);
        }
      }).catch(e => {

    }).finally(() => {
      setLoading(false)
    })
  }


  return (
    <Layout>
      {showExtension && <BuildUpExtension />}
      <Main>
        <TitleContainer style={{ marginBottom: '60px' }}>
          <TitleText>
            <h3>
              Short courses
            </h3>
          </TitleText>
        </TitleContainer>
        {loading ? <Loading />:
        shortTermCourses.map((course, index) => <CourseITem course={course} index={index}
                                                             enrolled={enrolledCourses.includes(course.id)}
                                                             onEnroll={handleEnroll}
                                                             maxIndex={shortTermCourses.length - 1} />)}

        <LongtermCourses>
          <TitleContainer style={{ marginBottom: '60px' }}>
            <TitleText>
              <h3>
                Long-term courses
              </h3>
            </TitleText>
          </TitleContainer>

          {loading ? <Loading />:
          longTermCourses.map((course, index) => <CourseITem course={course} index={index}
                                                              enrolled={enrolledCourses.includes(course.id)}
                                                              onEnroll={handleEnroll}
                                                              maxIndex={longTermCourses.length - 1} />)}


        </LongtermCourses>
      </Main>
    </Layout>
  )
}

export default Courses
