import * as React from 'react'
import styled from 'styled-components'
import LoadingGif from '../../images/loading.gif'
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Loading = () => {
  return <LoadingContainer>
    <img src={LoadingGif} alt={'Loading'} width={400}/>
  </LoadingContainer>
}

export default Loading;
